<template>
  <div>
    <SubviewCard
      style="cursor: pointer"
      :padding="false"
      :radius="50"
      :shadow="0.15"
      v-ripple
      noActions
    >
      <v-layout align-center @click="showPatientCardDialog">
        <RoundInitialsButton
          :size="42"
          :name="doctor.doctor_name"
          :loading="clicked"
          @clicked="showPatientCardDialog"
        ></RoundInitialsButton>
        <v-layout column mx-4>
          <DefaultLabel ellipsis color="primary">{{
            doctor.doctor_name
          }}</DefaultLabel>
          <v-layout>
            <DefaultSubText class="mr-1">
              Wizyty:
              <b>{{ doctor.appointments }}</b>
            </DefaultSubText>
            <DefaultSubText class="ml-1">
              Leczenia:
              <b>{{ doctor.sessions }}</b>
            </DefaultSubText>
            <v-spacer></v-spacer>
          </v-layout>
        </v-layout>
        <v-icon class="primary--text mr-4">fas fa-history</v-icon>
      </v-layout>
    </SubviewCard>
    <PatientCardDialog
      ref="patientCardDialog"
      v-if="patientCardDialogVisible"
      :patient="patient"
      :owner="owner"
      :value.sync="patientCardDialogVisible"
      @success="handleSuccess"
      @error="handleError"
    ></PatientCardDialog>
  </div>
</template>

<script>
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";

export default {
  mixins: [ProxyCRUDMixin],
  props: {
    doctor: {},
    patient: {},
    saveBeforeOpen: {},
    fetchAfterClose: {},
  },
  watch: {
    patientCardDialogVisible() {
      if (!this.patientCardDialogVisible && this.fetchAfterClose) {
        this.fetchAfterClose();
      }
    },
  },
  data() {
    return {
      patientCardDialogVisible: false,
      clicked: false,
    };
  },
  computed: {
    owner() {
      let own = {};
      own.id = this.doctor.doctor_id;
      own.name = this.doctor.doctor_name;
      return own;
    },
  },
  components: {
    SubviewCard: () => import("@/components/cards/SubviewCard"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    DefaultSubText: () => import("@/components/text/DefaultSubText"),
    PatientCardDialog: () =>
      import("@/components/popups/patientcard/PatientCardDialog"),
    RoundInitialsButton: () =>
      import("@/components/buttons/RoundInitialsButton"),
  },
  methods: {
    async showPatientCardDialog() {
      this.clicked = true;

      if (this.saveBeforeOpen) {
        this.saveBeforeOpen().then(() => {
          this.clicked = false;
          this.patientCardDialogVisible = true;
        });
      } else {
        this.clicked = false;
        this.patientCardDialogVisible = true;
      }
    },
  },
};
</script>